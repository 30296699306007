import { getCookiesConsent } from 'cookies'
import type { Session } from 'session/src/types/session'
import type { MasterData } from 'types/masterData'

import { AnalyticsEvents } from '../../constants/events'
import type { DataLayerDataObject } from '../../types/DataLayerEvent'

export const getDataLayerData = (
	masterData: MasterData,
	session: Session
): DataLayerDataObject => {
	const {
		channel,
		country,
		environment,
		pageType,
		device,
		brand,
		custom,
		isApp,
	} = masterData

	const { logged } = session

	const analyticsConsent = getCookiesConsent()?.analyticsConsent ?? false
	const adsConsent = getCookiesConsent()?.adsConsent ?? false

	const dataLayer = {
		...custom,
		environment,
		application: channel,
		ads_consent: adsConsent,
		analytics_consent: analyticsConsent,
		country_iso: country.countryISO,
		email: logged ? session.hashedEmail : undefined,
		external_id: logged ? session.externalId : undefined,
		event: AnalyticsEvents.DATALAYER_DATA,
		error: null,
		has_online_sale: country.hasOnlineSale,
		is_app: false,
		is_votf: false,
		language_iso: country.languageISO,
		page_brand: brand,
		page_type: pageType,
		platform: device,
		user_logged: session.logged,
		genesis: true,
	} as DataLayerDataObject

	if (isApp) {
		return {
			...dataLayer,
			screen_class: pageType.toString(),
			screen_name: custom?.page_title ?? window.location.toString(),
		} as DataLayerDataObject
	}

	return dataLayer
}
