export enum featureFlagKeys {
	AccountBalance = '_checkout__account_balance_countries',
	AkamaiEndpoints = 'akamai_endpoints',
	BestSellerTag = 'show_pdp_tag_bestseller',
	Chatbot = 'chatbot_toggle',
	Checkout = '_checkout__new_checkout',
	CIAM = 'all_ciam',
	CookiesBanner = 'cookies_banner',
	CountryLanguage = 'all_country_language',
	Dam = 'dam',
	Discover = 'rollout_discover_by_mango',
	EditActivation = 'edit_activation',
	EnableDigitalReturns = 'enable_digital_returns',
	FashionAssistant = 'fashion_assistant',
	GenesisPdp = 'pdp_genesis_activation',
	PdpNewVisionMvp = 'pdp_new_vision_mvp',
	Header_black_ribbon = 'header_black_ribbon',
	HelpPage = 'page_help',
	HighlightedDiscount = 'pdp_highlighted_discount',
	HomeA11yHiddenLink = 'homes_a11y_hidden_link',
	/** Used in monolith and /h page to redirect to monolith/genesis */
	HomeMultibrand = 'homes_multibrand_banners',
	/** Used in homepages to enable or disable an specific brand (included multibrand) */
	Homes = 'homes_homebrand',
	HomesEnableGiftVoucher = 'homes_enable_gift_voucher',
	HomesLinkToFashionAssistant = 'homes_link_to_fashion_assistant',
	HomesLinkToGiftVoucher = 'homes_link_to_gift_voucher',
	HomesThrowErrorOnFailingSectionId = 'homes_throw_error_on_failing_section_id',
	LabelsTranslations = 'labels_translations',
	MangoLikesYouClubTiers = 'mango_likes_you_club_tiers',
	MangoLikesYouPage = 'page_mango_likes_you',
	MyAddressesPage = 'page_my_addresses',
	MyBalancePage = 'page_my_balance',
	MyMangoLikesYouPage = 'page_my_mango_likes_you',
	MyPersonalInfoPage = 'page_my_personal_info',
	MyPurchasesShow = 'mypurchases_show',
	MyRefundsPage = 'page_my_refunds',
	MySubscriptionsPage = 'page_my_subscriptions',
	NewsletterConfiguration = 'newsletter_configuration',
	NonCriticalFeatures = 'non-critical_features',
	OmnibusExceptionCountries = 'price_omnibus_countries',
	OrderCancellation = 'mypurchases_cancel_order',
	PaymentsIdealBanks = 'payments_ideal_banks',
	PaymentsKlarnaCLTV = 'payments_klarna_cltv',
	PaymentsProcessOut = 'payments_processout',
	PaymentStoredCards = 'payments_stored_cards',
	PlpBannersVasava = '_plp__banners__vasava_active',
	PlpBestSellers = 'plp_bestsellers',
	PlpSegmentation = 'plp_genesis_segmentation',
	PlpVersion = '_plp__genesis__activation_versions',
	PrivacyPolicyConfiguration = 'privacy_policy_configuration',
	PrivacyPolicyRequestForm = 'privacy_policy_request_form',
	ProductCardOverlayLabel = 'productcard_overlay_label',
	ProductCarousel = 'shopping_cart_product_carousel',
	PromoBanner = 'promo_countdown',
	PurchaseEventInBack = '_checkout__purchase_event_in_back',
	RegistryPostalCode = 'registry_postal_code',
	ReturnHasCost = 'return_has_cost_since_date',
	ReturnsDisableDroppointMethod = 'returns_disable_droppoint_method',
	ReturnsFeedback = 'enable_returns_feedback',
	SecondReturnsBlock = 'block_second_returns',
	ShoppingCartRecommendationXselling = 'shopping_cart_recommendation_xselling',
	ShowDiscount = 'price_show_discount',
	ShowSavedPrice = 'cart_show_saved_price',
	PlpConfig = 'plp_config',
	HomeMultiBrandImage = 'home_multibrand_image',
	RecoveryPasswordActivated = 'login_activate_forgot_password_genesis',
	PaymentEnablePaymentMethod = 'payment_enable_payment_method',
	BannerApp = 'enable_banner_app',
}

export type NonCriticalFeaturesVariables = {
	favorites: boolean
	multiaddress: boolean
	my_purchases: boolean
	order_status_banner: boolean
	personalization_cart: boolean
	personalization_pdp: boolean
	personalization_plp: boolean
	personalization_search: boolean
}
